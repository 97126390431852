<template>
  <div @mousemove="moveEvent" class="asda">
    <el-container class="home-container">
      <!-- 头部区域 -->
      <el-header>
        <div class="logo">
          <img class="shoujia" src="../assets/logo.png" alt />

          <div class="text">商用车大数据平台</div>
        </div>

        <div class="right">
          <div class="mess">
            <img class="messa" src="../assets/image/yaoshi.png" alt />
            <div class="messb">修改密码</div>
          </div>
          <div class="mess" @click="logout">
            <img class="messa" src="../assets/image/tuichu.png" alt />
            <div class="text">退出</div>
          </div>
          <div class="mess messag">
            <img class="messa messaimg" :src="userlist.avatarUrl" alt />
            <div class="textname">{{ userlist.phone }}</div>
          </div>
        </div>
      </el-header>
      <!-- 页面主体区域 -->
      <!-- 222222 -->
      <el-container>
        <!-- 侧边栏 -->
        <el-aside :width="'200px'">
          <!-- 侧边栏菜单区域 -->
          <el-menu background-color="#0083E3" text-color="#fff" active-text-color="#0083E3" :unique-opened="true"
            :collapse-transition="false" :default-active="activePath" router @open="handleOpen" @close="handleClose">
            <el-menu-item index="/index">
              <img class="imga" :src="indexIcon" v-if="indexIcon" alt />

              <span slot="title">首页</span>
            </el-menu-item>
            <!-- :default-active="activePath" -->
            <!-- 一级菜单 -->
            <el-submenu v-for="item in menulist" :key="item.menuId" :index="item.menuCode">
              <!-- 一级菜单的模板区域 -->
              <template slot="title">
                <!-- 图标 -->
                <img class="imga" :src="item.imageUrl" alt />
                <!-- <i :class="iconList[item.id]"></i> -->
                <!-- 文本 -->
                <span>{{ item.menuName }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item :index="subItem.menuUrl" v-for="subItem in item.children" :key="subItem.menuId">
                <!-- @click="saveNavState('/'+subItem.menuUrl)" -->
                <template slot="title">
                  <!-- 图标 -->
                  <!-- <i class="el-icon-menu"></i> -->

                  <!-- 文本 -->
                  <span>{{ subItem.menuName }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <!-- 主体结构 -->
        <el-main>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
var currentTime = new Date().getTime(); //当前时间
var timeOut = 10 * 60 * 1000; //设置超时时间： 10分
export default {
  data() {
    return {
      // 被激活的链接地址
      activePath: "",
      menulist: [], //左侧菜单数据
      indexIcon: "",
      userlist: [], //获取用户数据
    };
  },
  // this.$route.path当前路由地址
  beforeUpdate() {
    this.activePath = this.$route.path;
    // console.log(this.activePath);
  },
  mounted() { },
  created() {
    this.getMenuList(); //页面一加载就获取数据
    this.getUserInfoList(); //页面一加载获取用户信息数据
    // this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    // 10分钟不动退出
    moveEvent() {
      let path = ["/login"];
      if (!path.includes(this.$route.path)) {
        clearTimeout(this.timmer);
        this.init();
      }
    },
    init() {
      this.timmer = setTimeout(async () => {
        const { data: res } = await this.$http.post("logout");
        window.sessionStorage.clear();

        this.$alert("你很久未操作，系统已退出登录；", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$message.success("退出成功");
            this.$router.push({
              path: "/login",
            });
          },
        });
      }, 1000 * 60 * 60); //
    },

    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    // 退出登录
    async logout() {
      const { data: res } = await this.$http.post("logout");

      if (res.code !== 200) return this.$message.error("退出失败");
      this.$message.success("退出成功");
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.$router.push("/login");
    },
    // 获取所有菜单
    async getMenuList() {
      const { data: res } = await this.$http.get("userMenu");
      if (res.code !== 200)
        return this.$message.error(res.consoleMsg || "error");
      this.menulist = res.data.filter((item) => {
        if (item.menuName === "首页") {
          this.indexIcon = item.imageUrl; //拿到首页图片的路径
        }
        return item.menuName !== "首页";
      });
      // console.log(this.menulist);
    },
    async getUserInfoList() {
      const { data: res } = await this.$http.get("userInfo");
      if (res.code === 103) {
        return;
      }
      // console.log(res.data.status);
      // console.log(res.data, "用户数据");
      if (res.code !== 200)
        return this.$message.error(res.consoleMsg || "error");
      this.userlist = res.data;
      if (res.data.status === "normal") {
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu-item.is-active {
  background: #ffffff !important;

  color: #0083e3 !important;
  border-radius: 10px 0px 0px 10px;
}

.imga {
  margin-right: 10px;
  background: #0083e3;
}

.logo {
  display: flex;
  align-items: center;
  width: 400px;

  .shoujia {
    width: 41px;
    height: 41px;
  }

  .text {
    width: 312px;
    height: 37px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 37px;
    color: #333333;
    opacity: 1;
  }
}

.right {
  display: flex;

  .mess {
    display: flex;
    height: 40px;
    border-right: 1px solid #cccccc;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    .textname {
      width: 100px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
    }

    .messa {
      width: 22px;
      height: 22px;
      margin-right: 7px;
      margin-left: 10px;
    }

    .messaimg {
      width: 48px;
      height: 48px;

      border-radius: 50%;
      opacity: 1;
    }

    .messb {
      width: 64px;

      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
      margin-right: 25px;
    }

    .text {
      width: 32px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
      margin-right: 25px;
    }
  }

  .messag {
    border: none;
  }
}

.home-container {
  height: 100%;
}

.el-header {
  // width: 1200px;
  height: 88px;
  background: #ffffff;
  opacity: 1;
  line-height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-aside {
  background-color: #0083e3;
}

.el-main {
  background: #f4f7fe;
}

.asda {
  width: 100%;
  height: 100vh;
}
</style>
